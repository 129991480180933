<template>
  <section id="basic-input">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-form @submit.prevent>
              <div class="row">
                <div class="col-10"></div>
                <div class="col-2">
                  <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="getAll"
                  >
                    Getir
                  </b-button>

                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-success"
                      @click="showModal"
                  >
                    Yeni Ekle
                  </b-button>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {BFormGroup, BForm, BFormInput, BButton, BModal} from "bootstrap-vue";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: "Search",
  components: {BFormGroup, BForm, BFormInput, BButton, BModal, ToastificationContent},
  directives: {
    Ripple,
  },
  methods: {
    getAll() {
      this.$store.dispatch('productCategory/getAll')
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Kategoriler Geldi',
                variant: res,
                icon: 'ArrowDownCircleIcon',
              },
            })
          })
    },
    showModal() {
      this.$store.commit('productCategory/setSelected', {"name": "", "parent_id": null});
      this.$root.$emit('bv::show::modal', 'edit-category-modal', '#btnShow')
    }
  },
  computed: {
    ...mapGetters('productCategory', ["_categories"]),
  }
}
</script>

<style scoped>

</style>
