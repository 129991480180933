<template>
  <div>
    <b-modal
        id="edit-category-modal"
        ref="edit-category-modal"
        cancel-variant="outline-secondary"
        ok-title="Kaydet"
        @ok="handleOk"
        cancel-title="Kapat"
        centered
        title="Yeni Kategori Ekle"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-form-group>
            <label for="categoryName">Kategori Adı:</label>
            <validation-provider
                #default="{ errors }"
                name="Kategori Adı"
                rules="required"
            >
              <b-form-input
                  v-model="_selected.name"
                  :state="errors.length > 0 ? false:null"
                  id="categoryName"
                  type="text"
                  placeholder="Kategori Adı Girin"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label for="ParentCategory">Üst Kategori</label>
            <b-form-select
                id="ParentCategory"
                v-model="_selected.parent_id"
                value-field="id"
                text-field="name"
                :options="_categories"
            />
          </b-form-group>
        </b-form>
      </validation-observer>

    </b-modal>
  </div>

</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import {BModal, BFormGroup, BForm, BFormInput, BButton, BFormSelect} from "bootstrap-vue";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: "EditCategory",
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BFormGroup,
    BForm,
    BFormInput,
    BButton,
    BFormSelect,
    ToastificationContent
  },
  data() {
    return {
      required
    }
  },
  directives: {
    Ripple,
  },
  methods: {
    showModal() {
      this.$refs['edit-category-modal'].show();
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault() // Prevent modal from closing
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this._selected.id) {
            this.$store.dispatch('productCategory/edit', this._selected)
                .then(res => {
                  if (res.id) {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: res.name + ' Düzeltildi',
                        variant: 'primary',
                        icon: 'SaveIcon',
                      },
                    })
                  }
                })
          } else {
            this.$store.dispatch('productCategory/addNewCategory', this._selected)
                .then(res => {
                  if (res.id) {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: res.name + ' Eklendi',
                        variant: 'success',
                        icon: 'SaveIcon',
                      },
                    })
                  }
                })
          }
        }
      })
    }
  },
  computed: {
    ...mapGetters('productCategory', ["_categories", "_selected"]),
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
