<template>
  <div class="row" id="table-hover-row">
    <div class="col-12">
      <div class="card">
        <div class="table-responsive">
          <table class="table table-hover table-sm">
            <thead>
            <tr>
              <th>Sıra No</th>
              <th>Kategori Adı</th>
              <th>Üst Kategori</th>
              <th>Durum</th>
              <th>Yönet</th>
            </tr>
            </thead>
            <tbody>
            <Category
                v-for="category in categories"
                :key="category.id"
                :category="category"
                :editStatus="true"
            >
            </Category>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Category from "@/views/product-category/Category";

export default {
  name: "Categories",
  components: {Category},
  props: {
    propCategories: {
      type: Array,
      required: false
    }
  },
  computed: {
    ...mapGetters('productCategory', ["_categories"]),
    categories() {
      if (this.propCategories) {
        return this.propCategories
      }
      return this._categories;
    }
  },

}
</script>

<style scoped>

</style>
