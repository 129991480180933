<template>
  <section id="card-text-alignment">
    <!-- Basic Inputs start -->
    <Search></Search>
    <!-- Basic Inputs end -->

    <!-- Hoverable rows start -->
    <Categories></Categories>
    <!-- Hoverable rows end -->

    <EditCategory></EditCategory>
  </section>
</template>

<script>
import EditCategory from "@/views/product-category/EditCategory";
import Search from "@/views/product-category/Search";
import Categories from "@/views/product-category/Categories";

export default {
  name: "CategoryHome",
  components: {Search, Categories, EditCategory}
}
</script>

<style scoped>

</style>
